<div class="flex flex-col pb-1">
    <mat-form-field class="w-60">
        <mat-label></mat-label>
        <mat-icon
            class="icon-size-5"
            [svgIcon]="'account_balance_wallet'" matPrefix></mat-icon>
        <mat-select
            [value]="selectedWalletInfo.id"
            (selectionChange)="selectWallet($event.value)">
            <ng-container *ngFor="let wallet of walletInfos; trackBy: trackByFn">
                <mat-option [value]="wallet.id">{{wallet.description}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
</div>
