import { Component } from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {WalletService} from "./modules/admin/apps/wallet/wallet.service";
import {LocalService} from "../@fuse/directives/local.service";

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent {

    private readonly selectedWalletInfoId: string;
    b3SyncPhase: string;

    constructor(
        private _toastr: ToastrService,
        private _walletService: WalletService,
        private localService: LocalService) {
        this.selectedWalletInfoId = this.localService.getJsonValue('selectedWalletInfoId')
    }

    consolidateWallet(isLoginConsolidation) {
        this._toastr.info("Consolidando sua carteira...")
        this._walletService.consolidateWallet(this.selectedWalletInfoId, isLoginConsolidation)
            .subscribe({
                next: (result) => {
                    this._toastr.success('Carteira consolidada com sucesso!')
                },
                error: (error) => {
                }
            });
    }
}
