import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import localePt from '@angular/common/locales/pt';
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFireDatabaseModule} from "@angular/fire/compat/database";
import {environment} from "../environments/environment";
import {ToastrModule} from "ngx-toastr";
import {registerLocaleData} from "@angular/common";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {getPortuguesePaginatorIntl} from "./paginator-i18n";
import {NgApexchartsModule} from "ng-apexcharts";
import {AngularFirestoreModule} from "@angular/fire/compat/firestore";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import firebase from "firebase/compat/app";
import {NgxMaskModule} from "ngx-mask";

registerLocaleData(localePt);

const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

if (!firebase.apps.length) {
    firebase.initializeApp(environment.firebaseConfig);
}
export const auth = firebase.auth();
export const firestore = firebase.firestore()

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        //firebase
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        AngularFireAuthModule,

        ToastrModule.forRoot({
            // maxOpened: 1,
            preventDuplicates: true,
            positionClass: 'toast-top-right',
            newestOnTop: true,
            // progressBar: true
        }),
        NgApexchartsModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        {
            provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl(),
        },
    ],
    bootstrap   : [
        AppComponent
    ],
})
export class AppModule
{
}
