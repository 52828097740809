import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injectable,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import {UnshareWalletResponse, WalletInfoResponse} from "./select-wallet.types";
import {WalletInfoService} from "./service/wallet-info.service";
import {FuseNavigationService} from "../../../../@fuse/components/navigation";
import {defaultNavigation} from "../../../mock-api/common/navigation/data";
import {ClassicLayoutComponent} from "../../layouts/vertical/classic/classic.component";
import {LocalService} from "../../../../@fuse/directives/local.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {SelectWalletService} from "./select-wallet.service";
import {ProfileResponse} from "../../../modules/admin/apps/dashboard/dashboard.types";

@Component({
    selector       : 'select-wallet',
    templateUrl    : './select-wallet.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'select-wallet'
})
@Injectable({ providedIn: 'root' })
export class SelectWalletComponent implements OnInit, OnDestroy
{
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    walletInfos: WalletInfoResponse[] = [];

    notifications: Notification[];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    selectedWalletInfo: WalletInfoResponse = new WalletInfoResponse();
    private selectedWalletInfoId: string;
    selectedWalletSharedInfoPermission: string;
    shared: boolean;
    profile: ProfileResponse

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _navigationService: FuseNavigationService,
        private _classicComponent: ClassicLayoutComponent,
        private _overlay: Overlay,
        private localService: LocalService,
        private _service: SelectWalletService,
        private walletInfoService: WalletInfoService,
        private _viewContainerRef: ViewContainerRef,
        private firestore: AngularFirestore) {
    }

    ngOnInit(): void {
        this.getAllWalletsByUser()
        this.getProfile()

        // Subscribe to notification changes
        this._notificationsService.notifications$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: Notification[]) => {

                this.notifications = notifications;
                this._calculateUnreadCount();
                this._changeDetectorRef.markForCheck();
            });
    }

    getProfile() {
        this._service.getProfile()
            .subscribe({
                next: (result) => {
                    this.profile = result
                    this.localService.setJsonValue('userName', this.profile.username)
                    this.localService.setJsonValue('name', this.profile.name)

                    if(this.selectedWalletInfoId != null) {
                        this.firestore.collection('unshare-wallet').doc(this.selectedWalletInfoId.toString() + " - " + this.profile.id).valueChanges()
                            .subscribe((data: UnshareWalletResponse) => {
                                if (data != undefined) {
                                    this.firestore.collection('unshare-wallet').doc(this.selectedWalletInfoId.toString() + " - " + this.profile.id).delete().then(r => {
                                        this.selectWallet(this.walletInfos.filter(element => element.principal)[0])
                                    })
                                }
                            });
                        this.firestore.collection('update-wallet-permission').doc(this.selectedWalletInfoId.toString() + " - " + this.profile.id).valueChanges()
                            .subscribe((data: UnshareWalletResponse) => {
                                if (data != undefined) {
                                    localStorage.setItem('selectedWalletSharedInfoPermission', data.permission.toUpperCase())
                                    this.firestore.collection('update-wallet-permission').doc(this.selectedWalletInfoId.toString() + " - " + this.profile.id).delete().then(r => {
                                        window.location.reload();
                                    })
                                }
                            });
                    }

                    this._changeDetectorRef.markForCheck();
                },
                error: (error) => {
                    return
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        if ( this._overlayRef ) {
            this._overlayRef.dispose();
        }
    }

    openPanel(): void {
        if ( !this._notificationsPanel || !this._notificationsOrigin ) {
            return;
        }
        if ( !this._overlayRef ) {
            this._createOverlay();
        }
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        // Mark all as read
        this._notificationsService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Notification): void
    {
        // Toggle the read status
        notification.read = !notification.read;

        // Update the notification
        this._notificationsService.update(notification.id, notification).subscribe();
    }

    /**
     * Delete the given notification
     */
    delete(notification: Notification): void
    {
        // Delete the notification
        this._notificationsService.delete(notification.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void
    {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : 'fuse-backdrop-on-mobile',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                                  .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                                  .withLockedPosition(true)
                                  .withPush(true)
                                  .withPositions([
                                      {
                                          originX : 'start',
                                          originY : 'bottom',
                                          overlayX: 'start',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'start',
                                          originY : 'top',
                                          overlayX: 'start',
                                          overlayY: 'bottom'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'bottom',
                                          overlayX: 'end',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'top',
                                          overlayX: 'end',
                                          overlayY: 'bottom'
                                      }
                                  ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void
    {
        let count = 0;

        if ( this.notifications && this.notifications.length )
        {
            count = this.notifications.filter(notification => !notification.read).length;
        }

        this.unreadCount = count;
    }

    getAllWalletsByUser() {
        this.walletInfoService.getAllWalletsByUser()
            .subscribe(data => {
                this.walletInfos = data

                let selectedWalletInfoId = this.localService.getJsonValue('selectedWalletInfoId');
                if (this.walletInfos.filter(element => element.id.toString() == selectedWalletInfoId).length == 0) {
                    this.localService.setJsonValue('selectedWalletInfoId', this.walletInfos[0].id.toString());
                }

                selectedWalletInfoId = this.localService.getJsonValue('selectedWalletInfoId');
                if (selectedWalletInfoId == null) {
                    this.selectedWalletInfo = this.walletInfos.filter(element => element.principal)[0]
                    this.localService.setJsonValue('selectedWalletInfoId', this.selectedWalletInfo.id.toString());
                } else {
                    this.selectedWalletInfo = this.walletInfos.filter(element => element.id.toString() == selectedWalletInfoId)[0]
                    this.selectedWalletSharedInfoPermission = this.selectedWalletInfo.permission;
                    this.shared = this.selectedWalletInfo.shared;
                    this.localService.setJsonValue('selectedWalletSharedInfoPermission', this.selectedWalletInfo.permission)
                    this.localService.setJsonValue('selectedWalletInfoId', this.selectedWalletInfo.id.toString())

                    if (this.selectedWalletInfo.shared && this.selectedWalletInfo.permission == 'VIEW') {
                        this._navigationService.getItem('dashboards.goal', defaultNavigation).hidden = (item => {return true})
                        this._navigationService.getItem('dashboards.followAssets', defaultNavigation).hidden = (item => {return true})
                        this._navigationService.getItem('dashboards.subscription', defaultNavigation).hidden = (item => {return true})
                        this._navigationService.getItem('apps.rebalancing', defaultNavigation).hidden = (item => {return true})
                        this._navigationService.getItem('apps.live', defaultNavigation).hidden = (item => {return true})
                        this._navigationService.getItem('connections', defaultNavigation).hidden = (item => {return true})
                        this._navigationService.getItem('connections.b3Connection', defaultNavigation).hidden = (item => {return true})
                        let navigation = {
                            default: defaultNavigation
                        }
                        this._classicComponent.generateNavigation(navigation)
                    }
                }
                this._classicComponent.getWalletResumedInfos(selectedWalletInfoId)
                this._changeDetectorRef.markForCheck();
                // this.selectedWalletToTransfer = this.walletInfos.filter(element => element.id.toString() == this.selectedWalletInfoId)[0];
            });
    }

    selectWallet(walletInfo) {
        if (this.selectedWalletInfoId != walletInfo) {
            window.location.reload();
        }
        this.selectedWalletInfoId = walletInfo;
        this.selectedWalletSharedInfoPermission = walletInfo.permission;
        this.shared = walletInfo.shared;
        this.localService.setJsonValue('selectedWalletSharedInfoPermission', walletInfo.permission)
        this.localService.setJsonValue('selectedWalletInfoId', walletInfo.toString())
    }
}
