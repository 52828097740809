export const environment = {
  production: false,
  dev: true,
  backendUrl: 'https://api-dev.carteiradeinvestimentos.com',
  firebaseConfig: {
    apiKey: "AIzaSyCjxqyJC9qi7xugSkxrxMEISUWXV8ky55Q",
    authDomain: "carteira-dev-bce08.firebaseapp.com",
    databaseURL: "https://carteira-dev-bce08-default-rtdb.firebaseio.com",
    projectId: "carteira-dev-bce08",
    storageBucket: "carteira-dev-bce08.appspot.com",
    messagingSenderId: "982591010382",
    appId: "1:982591010382:web:b36eb8a1a717ea2a86351f",
    measurementId: "G-J3EM4Z800G"
  },
  //DEV = "https://b3Investidorcer.b2clogin.com/b3Investidorcer.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_FINTECH&client_id=186e91d4-632d-4a96-87ab-e263d2c035ad&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.investidor.b3.com.br&scope=openid&response_type=code&prompt=login"
  b3Url: 'https://b3Investidor.b2clogin.com/b3Investidor.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_FINTECH&client_id=e69a70a4-e548-4005-a207-8ad1144b7b65&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.investidor.b3.com.br&scope=openid&response_type=code&prompt=login'
};
