import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    AssetsSearchResponse,
    CriptocoinsResponse,
    FinancialInstitutsResponse,
    InvestmentFundsResponse,
    NewAssetModel,
    PageableAssetHistoryModel,
    SettingsModel,
    TesouroDiretoResponse,
    WalletAssetGroupedResponse,
    WalletAssetModel, WalletMonthlyProfitabilityResponse,
    WalletResumeModel
} from 'app/modules/admin/apps/wallet/wallet.types';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import {
    ClassProfitabilityResponse,
    WalletAnnualProfitabilityResponse,
    WalletDailyProfitabilityResponse,
    WalletProfitabilityResumeResponse
} from "../profitability/profitability.types";
import {LocalService} from "../../../../../@fuse/directives/local.service";

@Injectable({
    providedIn: 'root'
})
export class WalletService
{
    constructor(private _httpClient: HttpClient,
                private localService: LocalService) {}

    public getFinancialsInstitutsByFilter(search): Observable<FinancialInstitutsResponse[]> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/broker/filter?search=' + encodeURIComponent(search), {headers});
    }

    public getCriptocoinsByFilter(search): Observable<CriptocoinsResponse[]> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/asset/criptocoin/filter?search=' + encodeURIComponent(search), {headers});
    }

    public searchAssetsByCode(search): Observable<AssetsSearchResponse[]> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/asset/filter?search=' + search, {headers});
    }

    public getInvestmentFundsByFilter(search): Observable<InvestmentFundsResponse[]> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/wallet/investmentFunds/filter?search=' + encodeURIComponent(search), {headers});
    }

    public getTesouroDiretoTitles(search): Observable<TesouroDiretoResponse[]> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/tesouroDireto/filter?search=' + encodeURIComponent(search), {headers});
    }

    public saveWalletAsset(asset, selectedWalletInfoId): Observable<NewAssetModel> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.post<any>(environment.backendUrl + '/v1/trade-rules/wallet?walletInfoId=' + selectedWalletInfoId, asset, {headers})
    }

    public getAssetDetails(id, walletInfoId): Observable<WalletAssetModel> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/wallet/' + id + '?walletInfoId=' + walletInfoId, {headers});
    }

    public updateBroker(id, broker): Observable<void> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.patch<any>(environment.backendUrl + '/v1/trade-rules/wallet/' + id, broker, {headers})
    }

    public getWalletAssetHistory(assetWalletHistoryFindRequest, selectedWalletInfoId, brokerId, page, size): Observable<PageableAssetHistoryModel> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.post<any>(environment.backendUrl + '/v1/trade-rules/wallet/asset/history/find?walletInfoId=' + selectedWalletInfoId + '&brokerId=' + brokerId + '&page=' + page + '&size=' + size, assetWalletHistoryFindRequest, {headers});
    }

    public delete(assetDeleteRequest, selectedWalletInfoId, brokerId): Observable<any> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.post<any>(environment.backendUrl + '/v1/trade-rules/wallet/delete?walletInfoId=' + selectedWalletInfoId + '&brokerId='+brokerId, assetDeleteRequest, {headers});
    }

    public consolidateWallet(selectedWalletInfoId, isLoginConsolidation): Observable<void> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}

        let request = environment.backendUrl + '/v1/trade-rules/wallet/consolidate'
        if (selectedWalletInfoId != null || isLoginConsolidation != null) {
            request = request + '?'
        }
        if (selectedWalletInfoId != null) {
            request = request + '&walletInfoId=' + selectedWalletInfoId;
        }
        if (isLoginConsolidation) {
            request = request + '&isLoginConsolidation=' + isLoginConsolidation;
        }

        return this._httpClient.post<any>(request, null, {headers})
    }

    public saveCategory(request, walletInfoId): Observable<any> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.post<any>(environment.backendUrl + '/v1/trade-rules/assetClass?walletInfoId=' + walletInfoId, request, {headers})
    }

    public updateCategory(id, request): Observable<any> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.put<any>(environment.backendUrl + '/v1/trade-rules/assetClass/' + id, request, {headers})
    }

    public getAvailablePercentGoal(walletInfoId): Observable<number> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/assetClass/available?walletInfoId=' + walletInfoId, {headers});
    }

    public linkAssetToClass(request): Observable<any> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.post<any>(environment.backendUrl + '/v1/trade-rules/assetClass/asset/link', request, {headers})
    }

    public unLinkAssetToClass(request): Observable<any> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.post<any>(environment.backendUrl + '/v1/trade-rules/assetClass/asset/unlink', request, {headers})
    }

    public deleteCategory(id): Observable<any> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.delete<any>(environment.backendUrl + '/v1/trade-rules/assetClass/' + id, {headers})
    }

    public getSettings(): Observable<SettingsModel> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/users/parameters', {headers});
    }

    public saveSettings(settings): Observable<SettingsModel> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.post<any>(environment.backendUrl + '/v1/users/parameters', settings, {headers})
    }

    public getByUserIdGroupedByAssetClass(walletInfoId, onlyActive, textToSearch): Observable<WalletAssetGroupedResponse[]> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        let url = '/v1/trade-rules/assetClass?walletInfoId=' + walletInfoId + '&onlyActive='+onlyActive
        if(textToSearch != null){
            url = url +'&filter='+encodeURIComponent(textToSearch)
        }
        return this._httpClient.get<any>(environment.backendUrl + url, {headers});
    }

    public updatePercent(id, percentage): Observable<void> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.put<any>(environment.backendUrl + '/v1/trade-rules/wallet/v2/updatePercent/' + id + '?percentage='+percentage, {headers})
    }

    public getPercentAvailableToSet(walletId): Observable<number> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/assetClass/v2/available?walletId='+walletId, {headers});
    }

    public getWalletResumedInfos(walletInfoId): Observable<WalletResumeModel> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/wallet/resume?walletInfoId='+walletInfoId, {headers});
    }

    public getWalletMonthlyProfitability(walletInfoId, year): Observable<WalletMonthlyProfitabilityResponse[]> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/wallet/profitability/monthly?walletInfoId=' + walletInfoId + '&year=' + year, {headers});
    }

    public getYearsBetweenInvestments(walletInfoId): Observable<any[]> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/wallet/yearsBetween?walletInfoId=' + walletInfoId, {headers});
    }

    public getWalletProfitabilityResume(walletInfoId): Observable<WalletProfitabilityResumeResponse> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/profitability/wallet/resume?walletInfoId=' + walletInfoId, {headers});
    }

    public getWalletDailyProfitability(walletInfoId, year): Observable<WalletDailyProfitabilityResponse[]> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/wallet/profitability/daily?walletInfoId=' + walletInfoId + '&year=' + year, {headers});
    }

    public getWalletAnnualProfitability(walletInfoId, year): Observable<WalletAnnualProfitabilityResponse[]> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/wallet/profitability/annual?walletInfoId=' + walletInfoId + '&year=' + year, {headers});
    }

    public getProfitabilityByClass(walletInfoId): Observable<ClassProfitabilityResponse[]> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this._httpClient.get<any>(environment.backendUrl + '/v1/trade-rules/assetClass/profitability?walletInfoId=' + walletInfoId, {headers});
    }
}
