import {ChartOptions} from "../../../modules/admin/apps/asset-detail/asset-detail.component";

export interface Notification
{
    id: string;
    icon?: string;
    image?: string;
    title?: string;
    description?: string;
    time: string;
    link?: string;
    useRouter?: boolean;
    read: boolean;
}

export class WalletInfoResponse {
    id: number;
    sharedId: number;
    description: string;
    principal: boolean;
    shared: boolean;
    permission: string;
    sharedWith: WalletInfoUsersResponse[];
    sharedFrom: boolean;
    invested: number;
    currentValue: number;
    profitability: number;
    profit: number;
    chartOptionsMonthly: Partial<ChartOptions>;
}

export class WalletInfoUsersResponse {
    id: number;
    emailToShare: string;
    permission: string;
}

export class UnshareWalletResponse {
    userId: number;
    permission: string;
}
