import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import {WalletService} from "../../../../modules/admin/apps/wallet/wallet.service";
import {WalletResumeModel} from "../../../../modules/admin/apps/wallet/wallet.types";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {LocalService} from "../../../../../@fuse/directives/local.service";
import {UnshareWalletResponse} from "../../../common/selectWallet/select-wallet.types";

@Component({
    selector     : 'classic-layout',
    templateUrl  : './classic.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    selectedWalletInfoId: string;
    walletResumeInfos: WalletResumeModel

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _walletService: WalletService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private firestore: AngularFirestore,
        private localService: LocalService) {
        this.selectedWalletInfoId = this.localService.getJsonValue('selectedWalletInfoId')
        if(this.selectedWalletInfoId != null) {
            this.firestore.collection('wallet-consolidation').doc(this.selectedWalletInfoId.toString()).snapshotChanges().subscribe(data => {
                this.getWalletResumedInfos(this.selectedWalletInfoId)
            });
        }
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

    generateNavigation(navigation){
        this.navigation = navigation;
    }

    ngOnInit(): void {
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.generateNavigation(navigation)
                this._changeDetectorRef.markForCheck();
            });

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

     toggleNavigation(name: string): void {
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
        if ( navigation ) {
            navigation.toggle();
        }
    }

    getWalletResumedInfos(selectedWalletInfoId) {
        this._walletService.getWalletResumedInfos(selectedWalletInfoId)
            .subscribe({
                next: (result) => {
                    this.walletResumeInfos = result
                    this._changeDetectorRef.markForCheck();
                },
                error: (error) => {
                }
            });
    }
}
