import { FuseNavigationItem } from '@fuse/components/navigation';

export let defaultNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Painel de Controle',
        subtitle: 'Acompanhe sua carteira de investimentos',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'dashboards.generalVision',
                title: 'Visão Geral',
                type : 'basic',
                icon : 'heroicons_outline:home',
                link : '/visao-geral',
            },
            {
                id   : 'dashboards.manageWallets',
                title: 'Carteiras',
                type : 'basic',
                icon : 'mat_solid:account_balance_wallet',
                link : '/carteiras'
            },
            {
                id   : 'dashboards.goal',
                title: 'Metas',
                type : 'basic',
                icon : 'heroicons_solid:badge-check',
                link : '/metas'
            },
            {
                id   : 'dashboards.followAssets',
                title: 'Seguir Ativos',
                type : 'basic',
                icon : 'mat_solid:add_chart',
                link : '/seguir-ativos'
            },
            {
                id   : 'dashboards.subscription',
                title: 'Assinatura PRO',
                type : 'basic',
                icon : 'feather:award',
                link : '/assinatura-pro'
            },
        ]
    },
    {
        id      : 'apps',
        title   : 'Carteira',
        subtitle: 'Administre seus ativos e alocações',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'apps.wallet',
                title: 'Ativos',
                type : 'basic',
                icon : 'heroicons_outline:trending-up',
                link : '/ativos'
            },
            {
                id   : 'apps.rebalancing',
                title: 'Rebalanceamento',
                type : 'basic',
                icon : 'heroicons_solid:scale',
                link : '/rebalanceamento'
            },
            {
                id   : 'apps.dividends',
                title: 'Proventos',
                type : 'basic',
                icon : 'heroicons_solid:calendar',
                link : '/proventos'
            },
            // {
            //     id   : 'apps.live',
            //     title: 'Ao Vivo',
            //     type : 'basic',
            //     icon : 'heroicons_outline:play',
            //     link : '/my-assets'
            // }
        ]
    },
    {
        id      : 'indicators',
        title   : 'Indicadores',
        subtitle: 'Principais indicadores de sua carteira',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'indicators.profitability',
                title: 'Rentabilidade',
                type : 'basic',
                icon : 'heroicons_solid:chart-bar',
                link : '/rentabilidade'
            },
        ]
    },
    {
        id: 'connections',
        title: 'Conexões',
        subtitle: 'Gerencie suas conexões',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'connections.b3Connection',
                title: 'Conexão B3',
                type: 'basic',
                icon: 'heroicons_outline:switch-horizontal',
                link: '/conexao-b3'
            },
        ]
    },
    {
        id      : 'contact',
        title   : 'Contato',
        subtitle: 'Tire dúvidas e entre em contato',
        type    : 'group',
        icon    : 'heroicons_outline:support',
        children: [
            {
                id   : 'contact.message',
                title: 'Entre em contato',
                type : 'basic',
                icon : 'heroicons_outline:support',
                link : '/contato',
            }
        ]
    },
];
