<fuse-loading-bar></fuse-loading-bar>

<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center h-20 p-6 pb-0">
            <img
                class="hidden dark:flex w-50"
                src="assets/images/logo/logo-white.png"
                alt="Logo Carteira">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<div class="flex flex-col flex-auto w-full min-w-0">
    <div class="relative flex flex-0 items-center w-full h-21 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <div class="grid grid-cols-4 divide-x mr-10" *ngIf="walletResumeInfos != null">
                <div class="flex flex-col items-center justify-center m-4 text-carteira">
                    <div class="text-xl font-semibold leading-none tracking-tighter text-center">
                        <div>{{walletResumeInfos.investedValue == null ? 0 : walletResumeInfos.investedValue | currency: 'R$'}}</div>
                    </div>
                    <div class="mt-1 text-center text-secondary text-sm">Investido</div>
                </div>
                <div class="flex flex-col items-center justify-center text-carteira">
                    <div class="text-xl font-semibold leading-none tracking-tighter text-center">
                        {{walletResumeInfos.currentValue == null ? 0 : walletResumeInfos.currentValue | currency: 'R$'}}
                    </div>
                    <div class="mt-1 text-center text-secondary text-sm">Saldo Bruto</div>
                </div>
                <div class="flex flex-col items-center justify-center text-carteira">
                    <div class="text-xl font-semibold leading-none tracking-tighter text-center">
                        {{walletResumeInfos.profitability == null ? 0 : walletResumeInfos.profitability | number: '1.2-2'}}%
                    </div>
                    <div class="mt-1 text-center text-secondary text-sm">Rentabilidade</div>
                </div>
                <div class="flex flex-col items-center justify-center text-carteira">
                    <div class="text-xl font-semibold leading-none tracking-tighter text-center">
                        {{walletResumeInfos.profit == null ? 0 : walletResumeInfos.profit | currency: 'R$'}}
                    </div>
                    <div class="mt-1 text-center text-secondary text-sm">Resultado</div>
                </div>
            </div>
            <select-wallet></select-wallet>
            <search [appearance]="'bar'"></search>
            <notifications></notifications>
            <user></user>
        </div>
    </div>

    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Carteira de Investimentos &copy; {{currentYear}}</span>
    </div>

</div>

