import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {WalletInfoResponse} from "../select-wallet.types";
import {environment} from "../../../../../environments/environment";
import {LocalService} from "../../../../../@fuse/directives/local.service";

@Injectable({
    providedIn: 'root'
})
export class WalletInfoService {

    constructor(
        private http: HttpClient,
        private localService: LocalService) {
    }

    public getAllWalletsByUser(): Observable<WalletInfoResponse[]> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this.http.get<any>(environment.backendUrl + '/v1/trade-rules/walletInfo', {headers});
    }

    public getPagedAllWalletsByUser(size: number, page: number): Observable<WalletInfoResponse[]> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this.http.get<any>(environment.backendUrl + '/v1/trade-rules/walletInfo', {headers});
    }

    public changePrincipal(id): Observable<void> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this.http.put<any>(environment.backendUrl + '/v1/trade-rules/walletInfo/principal/' + id, null, {headers})
    }

    public shareWallet(id, permission): Observable<void> {
        const accessToken = this.localService.getJsonValue('access_token');
        const headers = {'Authorization': 'Bearer ' + accessToken}
        return this.http.put<any>(environment.backendUrl + '/v1/trade-rules/walletInfoShare/' + id + '?permission='+permission, null, {headers})
    }

    // public generateCode(walletInfoId, permission): Observable<WalletInfoShareCodeResponse> {
    //     const accessToken = this.localService.getJsonValue('access_token');
    //     const headers = {'Authorization': 'Bearer ' + accessToken}
    //     return this.http.post<any>(environment.backendUrl + '/v1/trade-rules/walletInfoShare/generateCode?walletInfoId='+walletInfoId+'&permission='+permission, null, {headers})
    // }
    //
    // public followWalletByCode(code): Observable<WalletInfoShareCodeResponse> {
    //     const accessToken = this.localService.getJsonValue('access_token');
    //     const headers = {'Authorization': 'Bearer ' + accessToken}
    //     return this.http.post<any>(environment.backendUrl + '/v1/trade-rules/walletInfoShare/followWalletByCode?code='+code, null, {headers})
    // }
    //
    // public getShareCode(walletInfoId): Observable<WalletInfoShareCodeResponse> {
    //     const accessToken = this.localService.getJsonValue('access_token');
    //     const headers = {'Authorization': 'Bearer ' + accessToken}
    //     return this.http.get<any>(environment.backendUrl + '/v1/trade-rules/walletInfoShare/code?walletInfoId='+walletInfoId, {headers});
    // }
    //
    // createWalletInfo(walletInfo): Observable<any> {
    //     const accessToken = this.localService.getJsonValue('access_token');
    //     const headers = {'Authorization': 'Bearer ' + accessToken}
    //     return this.http.post<any>(environment.backendUrl + '/v1/trade-rules/walletInfo/', walletInfo, {headers})
    // }
    //
    // updateWalletInfoDescription(id, description): Observable<any> {
    //     const accessToken = this.localService.getJsonValue('access_token');
    //     const headers = {'Authorization': 'Bearer ' + accessToken}
    //     return this.http.put<any>(environment.backendUrl + '/v1/trade-rules/walletInfo/' + id + '?description=' + description, null, {headers})
    // }
    //
    // delete(id) {
    //     const accessToken = this.localService.getJsonValue('access_token');
    //     const headers = {'Authorization': 'Bearer ' + accessToken}
    //     return this.http.delete<any>(environment.backendUrl + '/v1/trade-rules/walletInfo/' + id, {headers});
    // }
    //
    // deleteSharedWallet(id) {
    //     const accessToken = this.localService.getJsonValue('access_token');
    //     const headers = {'Authorization': 'Bearer ' + accessToken}
    //     return this.http.delete<any>(environment.backendUrl + '/v1/trade-rules/walletInfoShare/' + id, {headers});
    // }
    //
    // deleteCode(code) {
    //     const accessToken = this.localService.getJsonValue('access_token');
    //     const headers = {'Authorization': 'Bearer ' + accessToken}
    //     return this.http.delete<any>(environment.backendUrl + '/v1/trade-rules/walletInfoShare/code/' + code, {headers});
    // }
    //
    // share(walletInfoToShare): Observable<WalletInfoShareResponse> {
    //     const accessToken = this.localService.getJsonValue('access_token');
    //     const headers = {'Authorization': 'Bearer ' + accessToken}
    //     return this.http.post<any>(environment.backendUrl + '/v1/trade-rules/walletInfoShare', walletInfoToShare, {headers})
    // }
    //
    // public getWalletResumedInfos(walletInfoId): Observable<WalletResumeModel> {
    //     const accessToken = this.localService.getJsonValue('access_token');
    //     const headers = {'Authorization': 'Bearer ' + accessToken}
    //     return this.http.get<any>(environment.backendUrl + '/v1/trade-rules/wallet/resume?walletInfoId='+walletInfoId, {headers});
    // }
}
